import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ERP, PLAN, V_URL } from '../../../../../BaseUrl';
import { getUserDepartment } from '../../../../../Store/Store/StoreMaster/Department/Department';
import PurchaseForm from '../../../../../Components/Validation/Request/PurchaseForm';
import moment from 'moment';
import axios from 'axios';
import { getItem } from '../../../../../Store/Store/Item/Item';
import { Modal } from 'react-bootstrap';
import PurchaseModal from '../../../../../Components/Validation/Request/PurchaseModal';
import { Pencil, Trash2 } from 'lucide-react';
import Swal from 'sweetalert2';
import { getUserProfile } from '../../../../../Store/Store/Profile/Profile';
import Header from '../../../Include/Header';
import Sidebar from '../../../Include/Sidebar';
import Footer from '../../../Include/Footer';
import { getDrawing } from '../../../../../Store/Erp/Planner/Draw/Draw';
import { getParty } from '../../../../../Store/Store/Party/Party';

const ManagePurchaseRequest = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [disable, setDisable] = useState(false);
    const [disable2, setDisable2] = useState(false);
    const [disable3, setDisable3] = useState(false);
    const [error, setError] = useState({});
    const [error2, setError2] = useState({});
    const [request, setRequest] = useState({
        requestDate: "",
        storeLocation: "",
        department: "",
        material_po: "",
        drawing_id: "",
    });
    const [finalId, setFinalId] = useState('');
    const [items, setItems] = useState([]);
    const [show, setShow] = useState(false);
    const [editModalId, setEditModalId] = useState('');
    const [disableTra, setDisableTra] = useState(true);
    const [selectedDrawing, setSelectedDrawing] = useState([]);
    const [itemVal, setItemVal] = useState({
        itemName: "",
        mcode: "",
        quantity: "",
        remarks: "",
        store_type: "",
        unit_rate: "",
        total_rate: "",
        preffered_supplier: ""
    });
    const [unit, setUnit] = useState('');
    const [mGrade, setMGrade] = useState('');

    useEffect(() => {
        // if (localStorage.getItem('PAY_USER_TOKEN') === null) {
        //     navigate("/user/login");
        // } else if (localStorage.getItem('VI_PRO') !== `${ERP}`) {
        //     toast.error('Access Denied. You do not have permission to view this product. Please contact your administrator for assistance.')
        //     navigate("/user/login");
        // }
        // if (localStorage.getItem('ERP_ROLE') !== `${PLAN}`) {
        //     toast.error('Access Denied. You do not have permission to view this product. Please contact your administrator for assistance.')
        //     navigate("/user/login");
        // }
    }, [navigate, disable]);

    const data = location.state;

    console.log(data, 'data')

    useEffect(() => {
        if (location.state) {
            setRequest({
                requestDate: moment(location.state?.requestDate).format('YYYY-MM-DD'),
                storeLocation: location.state.storeLocation,
                department: location.state?.department?._id,
                material_po: location.state?.material_po_no,
                drawing_id: location.state.drawing_id?._id,
            });
        }
    }, [location.state]);

    const departmentData = useSelector((state) => state.getUserDepartment?.user?.data);
    const itemApiData = useSelector((state) => state.getItem?.user?.data);
    const drawingData = useSelector((state) => state.getDrawing?.user?.data);
    const partyData = useSelector((state) => state.getParty?.user?.data);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(getUserDepartment()),
                    dispatch(getItem()),
                    dispatch(getUserProfile()),
                    dispatch(getDrawing()),
                    dispatch(getParty({ storeType: '' }))
                ]);
            } catch (error) {
                console.log(error, '!!');
            }
        }
        fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (disableTra === true) {
            getTrasactionItem();
            setItems([]);
        }
        // eslint-disable-next-line
    }, [disableTra]);

    const getTrasactionItem = () => {
        const url = `${V_URL}/user/get-transaction-item`;
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('tag', '3');
        // bodyFormData.append('store_type', '2');
        axios({
            method: "post",
            url: url,
            data: bodyFormData,
            headers: {
                Authorization: "Bearer " + localStorage.getItem("PAY_USER_TOKEN"),
            },
        }).then((response) => {
            if (response?.data.success === true) {
                const dataItem = response?.data?.data;
                const finalData = dataItem?.filter((da) => da?.requestId?._id === (data?._id || finalId));
                setItems(finalData);
            }
            setDisableTra(false);
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        const finalName = itemApiData?.find(it => it._id === itemVal?.itemName);
        setUnit(finalName?.unit?.name);
        setMGrade(finalName?.material_grade);
        setItemVal({ ...itemVal, mcode: finalName?.mcode })
        // eslint-disable-next-line
    }, [itemVal.itemName]);

    useEffect(() => {
        const selectedProject = drawingData?.filter((dr) => dr.project?._id === localStorage.getItem('U_PROJECT_ID'));
        setSelectedDrawing(selectedProject);
        // eslint-disable-next-line
    }, [drawingData, request.drawing_id])

    const handleChange = (e) => {
        setRequest({ ...request, [e.target.name]: e.target.value });
    }

    const handleChange2 = (e) => {
        setItemVal({ ...itemVal, [e?.target.name]: e?.target?.value })
    }

    useEffect(() => {
        if (itemVal.quantity && itemVal.unit_rate) {
            const total = itemVal.quantity * itemVal.unit_rate;
            setItemVal((prev) => ({ ...prev, total_rate: total }));
        }
    }, [itemVal.quantity, itemVal.unit_rate]);

    const handleSubmit = () => {
        if (validation()) {
            setDisable(true);
            const bodyFormData = new URLSearchParams();
            bodyFormData.append('year_id', localStorage.getItem('PAY_USER_YEAR_ID'));
            bodyFormData.append('firm_id', localStorage.getItem('PAY_USER_FIRM_ID'));
            bodyFormData.append('requestDate', request.requestDate);
            bodyFormData.append('storeLocation', request.storeLocation);
            bodyFormData.append('project', localStorage.getItem('U_PROJECT_ID'));
            bodyFormData.append('department', request.department);
            bodyFormData.append('preparedBy', localStorage.getItem('PAY_USER_ID'));
            bodyFormData.append('drawing_id', request.drawing_id);
            bodyFormData.append('material_po_no', request.material_po);

            bodyFormData.append('tag', '1');
            if (data?._id) {
                bodyFormData.append('id', data?._id);
            }
            axios({
                method: 'post',
                url: `${V_URL}/user/manage-request`,
                data: bodyFormData,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
            }).then((response) => {
                if (response.data.success === true) {
                    toast.success(response.data.message);
                    setFinalId(response.data.data._id)
                    // navigate('/user/project-store/purchase-request-management');
                }
                setDisable(false);
                setDisable3(true);
            }).catch((error) => {
                console.log(error, '!!');
                toast.error(error.response.data?.message || 'Something went wrong');
                setDisable(false);
            });
        }
    }

    const handleClose = () => {
        setShow(false);
        setEditModalId('');
        handleClearModal();
    }
    const handleShow = () => {
        setShow(true);
    }
    const handleEdit = (mData) => {
        setItemVal(mData);
        setEditModalId(mData?._id)
        setShow(true);
    }

    const handleDelete = (id, title) => {
        Swal.fire({
            title: `Are you sure want to delete ${title}?`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                const myurl = `${V_URL}/user/delete-transaction-item`;
                var bodyFormData = new URLSearchParams();
                bodyFormData.append("id", id);
                axios({
                    method: "delete",
                    url: myurl,
                    data: bodyFormData,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: "Barrer " + localStorage.getItem("PAY_USER_TOKEN"),
                    },
                }).then((response) => {
                    if (response.data.success === true) {
                        toast.success(response?.data?.message)
                    }
                    setDisableTra(true);
                }).catch((error) => {
                    toast.error(error?.response?.data?.message || "Something went wrong");
                })
            }
        })
    }

    const handleSubmit2 = (more) => {
        if (validation2()) {
            if (data?._id || finalId) {
                setDisable2(true)
                const myurl = `${V_URL}/user/manage-transaction-item`;
                const bodyFormData = new URLSearchParams();
                bodyFormData.append('tag', '3');
                bodyFormData.append('store_type', itemVal.store_type);
                bodyFormData.append('itemName', itemVal.itemName);
                bodyFormData.append('quantity', itemVal.quantity);
                bodyFormData.append('balance_qty', itemVal.quantity);
                bodyFormData.append('mcode', itemVal.mcode);
                bodyFormData.append('remarks', itemVal.remarks);
                bodyFormData.append('unit_rate', itemVal.unit_rate);
                bodyFormData.append('total_rate', itemVal.total_rate);
                bodyFormData.append('preffered_supplier', itemVal.preffered_supplier);
                if (data?._id) {
                    bodyFormData.append('requestId', data?._id);
                } else {
                    bodyFormData.append('requestId', finalId);
                }
                if (editModalId) {
                    bodyFormData.append('id', editModalId);
                }
                axios({
                    method: 'post',
                    url: myurl,
                    data: bodyFormData,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: "Bearer " + localStorage.getItem('PAY_USER_TOKEN') }
                }).then((response) => {
                    if (response.data.success === true) {
                        toast.success(response?.data?.message);
                        if (more === 'more') {
                            handleClearModal();
                        } else {
                            handleClearModal();
                            setShow(false)
                        }
                    } else {
                        toast.error(response?.data?.message)
                    }
                    setDisable2(false);
                    setDisableTra(true);
                }).catch((error) => {
                    console.log(error, '!!')
                    setDisable2(false);
                    toast.error(error?.response?.data?.message || 'Something went wrong');
                });
            }
        }
    }

    const handleClearModal = () => {
        setItemVal({
            itemName: "",
            mcode: "",
            quantity: "",
            remarks: "",
            store_type: "",
            unit_rate: "",
            total_rate: "",
            preffered_supplier: ""
        })
    }
    const validation2 = () => {
        const { isValid, err } = PurchaseModal({ itemVal });
        setError2(err);
        return isValid;
    }

    const validation = () => {
        const { isValid, err } = PurchaseForm({ request })
        setError(err);
        return isValid;
    }

    const handleReset = () => {
        setRequest({
            requestDate: "",
            storeLocation: "",
            department: "",
            prNo: "",
        });
    }

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
        }
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/user/project-store/dashboard">Dashboard </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right"></i>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/user/project-store/material-request-management">Raw Material Request List</Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right"></i>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        {data?._id ? "Edit" : "Add"} Raw Material Request
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <form>
                                        <div className="col-12">
                                            <div className="form-heading">
                                                <h4>{data?._id ? "Edit" : "Add"} Raw Material Request Details</h4>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label>Project Location <span className="login-danger">*</span></label>
                                                    <select className="form-control form-select"
                                                        value={request.storeLocation}
                                                        onChange={handleChange} name='storeLocation'>
                                                        <option value="">Select Project Location</option>
                                                        <option value={1}>Facility 1</option>
                                                        <option value={2}>Facility 2</option>
                                                        <option value={3}>Facility 3</option>
                                                    </select>
                                                    <div className='error'>{error.storeLocation_err}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label> Request Date <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="date"
                                                        onChange={handleChange} name="requestDate" value={request.requestDate} />
                                                    <div className="error">{error.requestDate_err}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label> Material PO No. <span className="login-danger">*</span></label>
                                                    <input className="form-control" type="text"
                                                        onChange={handleChange} name="material_po" value={request.material_po} />
                                                    <div className="error">{error.material_po_err}</div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label> Department <span className="login-danger">*</span></label>
                                                    <select className="form-control form-select"
                                                        value={request.department}
                                                        onChange={handleChange} name='department'>
                                                        <option value="">Select Department</option>
                                                        {departmentData?.map((e) => (
                                                            <option key={e._id} value={e._id}>
                                                                {e?.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <div className='error'>{error?.department_err}</div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-4 col-xl-4">
                                                <div className="input-block local-forms">
                                                    <label>Drawing No. </label>
                                                    <select className="form-control form-select"
                                                        value={request.drawing_id}
                                                        onChange={handleChange} name='drawing_id'>
                                                        <option value="">Select Drawing No</option>
                                                        {selectedDrawing?.map((e) => (
                                                            <option key={e._id} value={e._id}>
                                                                {e?.drawing_no}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <div className='error'>{error?.drawing_id_err}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {(data?.status !== 2 && data?.status !== 4) ? (
                                        <div className="col-12">
                                            <div className="doctor-submit text-end">
                                                <button type="button"
                                                    className="btn btn-primary submit-form me-2" onClick={handleSubmit} disabled={(disable || disable3) && !(data?._id && finalId)}>{disable ? "Processing..." : (data?._id ? 'Update and Continue' : 'Next and Continue')}</button>
                                                <button type="button"
                                                    className="btn btn-primary cancel-form" onClick={handleReset}>Reset</button>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    {data?._id || finalId ? (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form>
                                            <div className="col-12 d-flex justify-content-between align-items-center mb-4">
                                                <div className="form-heading">
                                                    <h4>Section Details List</h4>
                                                </div>
                                                {data?.status !== 6 ? (
                                                    <div className="add-group">
                                                        <button
                                                            type="button"
                                                            onClick={handleShow}
                                                            className="btn btn-primary add-pluss ms-2"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Add Material"
                                                        >
                                                            <img src="/assets/img/icons/plus.svg" alt="add-icon" />
                                                        </button>
                                                    </div>
                                                ) : null}
                                            </div>

                                            {items?.length > 0 ? (
                                                <div className="table-responsive">
                                                    <table className="table mb-0 custom-table table-striped comman-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr.</th>
                                                                <th>Section Details</th>
                                                                <th>Unit</th>
                                                                <th>M Code</th>
                                                                <th>Quantity</th>
                                                                <th>Unit / Total Rate</th>
                                                                <th>Store</th>
                                                                <th>Remark</th>
                                                                <th className="text-end">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {items?.map((elem, i) =>
                                                                <tr key={i}>
                                                                    <td>{i + 1}</td>
                                                                    <td>{itemApiData?.find(it => it?._id === elem?.itemName)?.name}</td>
                                                                    <td>{itemApiData?.find(it => it?._id === elem?.itemName)?.unit?.name}</td>
                                                                    <td>{elem?.mcode}</td>
                                                                    <td>{elem?.quantity}</td>
                                                                    <td>{elem?.unit_rate} / {elem?.total_rate}</td>
                                                                    <td>
                                                                        {elem?.store_type === 1 ? (
                                                                            <span className='custom-badge status-purple'>Main Store</span>
                                                                        ) : (
                                                                            <span className='custom-badge status-purple'>Project Store</span>
                                                                        )}
                                                                    </td>
                                                                    <td>{!elem?.remarks ? '-' : elem?.remarks}</td>
                                                                    <td className="d-flex justify-content-end">
                                                                        {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                                                        {(data?.status !== 2 && data?.status !== 4) ? (
                                                                            <a className='action-icon mx-1' style={{ cursor: "pointer" }}
                                                                                onClick={() => handleEdit(elem)}
                                                                                data-toggle="tooltip" data-placement="top" title="Edit">
                                                                                <Pencil />
                                                                            </a>
                                                                        ) : null}

                                                                        <a className='action-icon mx-1' style={{ cursor: "pointer" }}
                                                                            onClick={() => handleDelete(elem?._id, itemApiData?.find(it => it?._id === elem?.itemName)?.name)}
                                                                            data-toggle="tooltip" data-placement="top" title="Delete">
                                                                            <Trash2 />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : <h5>Looks like the item hasn't been added yet. Please make sure to include it. Thanks!</h5>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-12 text-end">
                                            <div className="doctor-submit text-end">
                                                <button type="button"
                                                    className="btn btn-primary submit-form me-2" onClick={() => navigate('/user/project-store/material-request-management')}>
                                                    {(data?.status !== 2 && data?.status !== 4) ? 'Submit' : 'Back'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <Footer />
            </div>

            <Modal show={show} onHide={handleClose} backdrop="static"
                keyboard={false} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Section Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='material-section'>
                        <div className="row align-items-center mt-2">
                            <div className="col-12 col-md-2">
                                <label className="col-form-label">Section Details <span className="login-danger">*</span> </label>
                            </div>
                            <div className="col-12 col-md-4">
                                <select className="form-control form-select"
                                    value={itemVal?.itemName}
                                    onChange={handleChange2} name='itemName'>
                                    <option value="">Select Section Details</option>
                                    {itemApiData?.map((e) => (
                                        <option key={e._id} value={e._id}>
                                            {e?.name} ({e?.mcode})
                                        </option>
                                    ))}
                                </select>
                                <div className='error'>{error2.itemName_err}</div>
                            </div>
                            <div className="col-12 col-md-2">
                                <label className="col-form-label">MCode</label>
                            </div>
                            <div className="col-12 col-md-4">
                                <input type="text" className="form-control" value={itemVal.mcode} onChange={handleChange2} name="mcode" />
                            </div>
                        </div>

                        {itemVal?.itemName ? (
                            <>
                                <div className="row align-items-center mt-2">
                                    <div className="col-12 col-md-2">
                                        <label className="col-form-label">Unit</label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <input type="text" className="form-control" value={unit} disabled />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label className="col-form-label">Grade</label>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <input type="text" className="form-control" value={mGrade || '-'} disabled />
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <div className="row align-items-center mt-2">
                            <div className="col-12 col-md-2">
                                <label className="col-form-label">Quantity <span className="login-danger">*</span></label>
                            </div>
                            <div className="col-12 col-md-4">
                                <input type="number" onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                                    className="form-control" value={itemVal.quantity}
                                    onChange={handleChange2} name="quantity" />
                                <div className='error'>{error2?.quantity_err}</div>
                            </div>
                            <div className="col-12 col-md-2">
                                <label className="col-form-label">Store <span className="login-danger">*</span></label>
                            </div>
                            <div className="col-12 col-md-4">
                                <select className="form-control form-select" onChange={handleChange2}
                                    disabled={editModalId ? true : false}
                                    name="store_type" value={itemVal?.store_type}>
                                    <option value="">Select Store Type</option>
                                    <option value={1}>Main Store</option>
                                    <option value={2}>Project Store</option>
                                </select>
                                <div className='error'>{error2?.store_type_err}</div>
                            </div>
                        </div>

                        <div className="row align-items-center mt-2">
                            <div className="col-12 col-md-2">
                                <label className="col-form-label">Unit Rate </label>
                            </div>
                            <div className="col-12 col-md-4">
                                <input type="number" onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                                    className="form-control" value={itemVal.unit_rate}
                                    onChange={handleChange2} name="unit_rate" />
                                <div className='error'>{error2?.unit_rate_err}</div>
                            </div>
                            <div className="col-12 col-md-2">
                                <label className="col-form-label">Total Rate </label>
                            </div>
                            <div className="col-12 col-md-4">
                                <input type="number" onWheel={(e) => e.target.blur()} onKeyDown={handleKeyDown}
                                    className="form-control" value={itemVal.total_rate}
                                    onChange={handleChange2} name="total_rate" disabled />
                                <div className='error'>{error2?.total_rate_err}</div>
                            </div>
                        </div>

                        <div className="row align-items-center mt-2">
                            <div className="col-12 col-md-2">
                                <label className="col-form-label">Preferred Supplier<span className="login-danger">*</span></label>
                            </div>
                            <div className="col-12 col-md-10 ">
                                <select className="form-control form-select" name="preffered_supplier"
                                    value={itemVal.preffered_supplier} onChange={handleChange2}>
                                    <option value=''>Select Preferred Supplier</option>
                                    {partyData?.map((e) =>
                                        <option value={e?._id} key={e?._id}>{e?.name}</option>
                                    )}
                                </select>
                                <div className='error'>{error2?.preffered_supplier_err}</div>
                            </div>
                        </div>

                        <div className="row align-items-center mt-2">
                            <div className="col-12 col-md-2">
                                <label className="col-form-label">Remark</label>
                            </div>
                            <div className="col-12 col-md-10">
                                <textarea className="form-control" value={itemVal.remarks} onChange={handleChange2} name="remarks" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-primary mr-2' type='button' onClick={handleSubmit2} disabled={disable2}>
                        {disable2 ? 'Processing...' : (!editModalId ? 'Save' : 'Update')}
                    </button>
                    {!editModalId ? (
                        <button className='btn btn-outline-primary' type='button' onClick={() => handleSubmit2('more')}>Add More</button>
                    ) : null}
                    <button className='btn btn-secondary' type='button' onClick={handleClearModal}>Reset</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ManagePurchaseRequest