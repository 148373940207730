import React, { useEffect, useMemo, useState } from 'react'
import Header from '../Include/Header';
import Sidebar from '../Include/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getStockReportList } from '../../../Store/Store/Stock/getStockReportList';
import Footer from '../Include/Footer';
import { Link } from 'react-router-dom';
import { Pagination, Search } from '../Table';
import DropDown from '../../../Components/DropDown';
import Loader from '../Include/Loader';
import moment from 'moment';
import { PdfDownloadErp } from '../../../Components/ErpPdf/PdfDownloadErp';

const StockReportList = () => {

    const dispatch = useDispatch();
    const [disable, setDisable] = useState(true)
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [limit, setlimit] = useState(10);

    useEffect(() => {
        if (disable === true) {
            dispatch(getStockReportList());
            setDisable(false);
        }
    }, [disable, dispatch]);

    const entity = useSelector((state) => state.getStockReportList?.user?.data)

    const commentsData = useMemo(() => {
        let computedComments = entity;
        if (search) {
            computedComments = computedComments.filter(
                (st) =>
                    st.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    st.requestId?.requestNo?.toString().toLowerCase()?.includes(search?.toLowerCase()) ||
                    st.imir_no?.toString().toLowerCase()?.includes(search?.toLowerCase())
            );
        }
        setTotalItems(computedComments?.length);
        return computedComments?.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, search, limit, entity]);

    const handleRefresh = () => {
        setDisable(true);
        setSearch('')
    }

    const handleDownload = () => {
        const bodyFormData = new URLSearchParams();
        bodyFormData.append('print_date', true);
        PdfDownloadErp({ apiMethod: 'post', url: 'download-stock-list', body: bodyFormData });
    }

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const handleOpen = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`main-wrapper ${isSidebarOpen ? 'slide-nav' : ''}`}>
            <Header handleOpen={handleOpen} />
            <Sidebar />

            <div className="page-wrapper">
                <div className="content">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/user/project-store/dashboard">Dashboard </Link></li>
                                    <li className="breadcrumb-item"><i className="feather-chevron-right"></i></li>
                                    <li className="breadcrumb-item active">Stock Report List</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {disable === false ? (
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card card-table show-entire">
                                    <div className="card-body">

                                        <div className="page-table-header mb-2">
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <div className="doctor-table-blk">
                                                        <h3>Stock Report List</h3>
                                                        <div className="doctor-search-blk">
                                                            <div className="top-nav-search table-search-blk">
                                                                <form>
                                                                    <Search
                                                                        onSearch={(value) => {
                                                                            setSearch(value);
                                                                            setCurrentPage(1);
                                                                        }} />
                                                                    {/* eslint-disable jsx-a11y/anchor-is-valid */}
                                                                    <a className="btn"><img src="/assets/img/icons/search-normal.svg"
                                                                        alt="search" /></a>
                                                                </form>
                                                            </div>
                                                            <div className="add-group">
                                                                <button type='button' onClick={handleRefresh}
                                                                    className="btn btn-primary doctor-refresh ms-2" data-toggle="tooltip" data-placement="top" title="Refresh"><img
                                                                        src="/assets/img/icons/re-fresh.svg" alt="refresh" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pageDropDown col-auto text-end float-end ms-auto download-grp">
                                                    <div className='add-group'>
                                                        <button className='btn w-100 btn btn-primary doctor-refresh me-2 h-100' type='button' onClick={handleDownload}>Download Report <i className="fa-solid fa-download mx-2"></i></button>
                                                    </div>
                                                    <DropDown limit={limit} onLimitChange={(val) => setlimit(val)} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="table-responsive">
                                            <table className="table border-0 custom-table comman-table  mb-0 datatable">
                                                <thead>
                                                    <tr>
                                                        <th>Sr.</th>
                                                        <th>Material PO No.</th>
                                                        <th>Req. No.</th>
                                                        <th>Section Details</th>
                                                        <th>Grade</th>
                                                        <th>unit</th>
                                                        <th>Req. Qty.</th>
                                                        <th>Off. Qty.</th>
                                                        <th>Off. Date</th>
                                                        <th>Off. Length</th>
                                                        <th>Off. Width</th>
                                                        <th>Off. NOS</th>
                                                        <th>Off. LOT no.</th>
                                                        <th>Inspected Qty.</th>
                                                        <th>Inspected Length</th>
                                                        <th>Inspected Width</th>
                                                        <th>Inspected LOT no.</th>
                                                        <th>Inspected Date</th>
                                                        <th>Rej. Qty.</th>
                                                        <th>Rej. Length</th>
                                                        <th>Rej. Width</th>
                                                        <th>IMIR NO.</th>
                                                        <th>Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {commentsData?.map((elem, i) =>
                                                        <tr key={elem?._id}>
                                                            <td>{(currentPage - 1) * limit + i + 1}</td>
                                                            <td>{elem?.material_po_no}</td>
                                                            <td>{elem?.requestNo}</td>
                                                            <td>{elem?.name}</td>
                                                            <td>{elem?.material_grade}</td>
                                                            <td>{elem?.unit}</td>
                                                            <td>{elem?.po_qty}</td>
                                                            <td>{elem?.offeredQty}</td>
                                                            <td>{moment(elem?.receiving_date).format('YYYY-MM-DD hh:mm')}</td>
                                                            <td>{elem?.offerLength || '-'}</td>
                                                            <td>{elem?.offerWidth || '-'}</td>
                                                            <td>{elem?.offerNos || '-'}</td>
                                                            <td>{elem?.lot_no || '-'}</td>
                                                            <td>{elem?.acceptedQty || '-'}</td>
                                                            <td>{elem?.acceptedLength || '-'}</td>
                                                            <td>{elem?.acceptedWidth || '-'}</td>
                                                            <td>{elem?.accepted_lot_no || '-'}</td>
                                                            <td>{elem.qc_date ? moment(elem.qc_date).format('YYYY-MM-DD hh:mm') : '-'}</td>
                                                            <td>{elem?.rejectedQty}</td>
                                                            <td>{elem?.rejected_length}</td>
                                                            <td>{elem?.rejected_width}</td>
                                                            <td>{elem?.imir_no || '-'}</td>
                                                            <td>{elem.balance_qty}</td>
                                                        </tr>
                                                    )}

                                                    {commentsData?.length === 0 ? (
                                                        <tr>
                                                            <td colspan="999">
                                                                <div className="no-table-data">
                                                                    No Data Found!
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="row align-center mt-3 mb-2">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6">
                                                <div className="dataTables_info" id="DataTables_Table_0_info" role="status"
                                                    aria-live="polite">Showing {Math.min(limit, totalItems)} from {totalItems} data</div>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                                                <div className="dataTables_paginate paging_simple_numbers"
                                                    id="DataTables_Table_0_paginate">
                                                    <Pagination
                                                        total={totalItems}
                                                        itemsPerPage={limit}
                                                        currentPage={currentPage}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : <Loader />}

                </div>
                <Footer />
            </div>
        </div>
    )
}

export default StockReportList;