import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../../Pages/Users/Dashboard/Dashboard';
import Drawing from '../../Pages/Users/Planner/Drawing/Drawing';
import ViewDrawing from '../../Pages/Users/Planner/Drawing/ViewDrawing';
import ManageDrawing from '../../Pages/Users/Planner/Drawing/ManageDrawing';
import PurchaseRequest from '../../Pages/Users/Planner/Request/Purchase/PurchaseRequest';
import ManagePurchaseRequest from '../../Pages/Users/Planner/Request/Purchase/ManagePurchaseRequest';
import Stock from '../../Pages/Users/Stock/Stock';
import AdjustmentTable from '../../Pages/Users/Adjustment/AdjustmentTable';
import PurchaseOrder from '../../Pages/Users/Transaction/Purchase/PurchaseOrder';
import ManagePurchaseOrder from '../../Pages/Users/Transaction/Purchase/ManagePurchaseOrder';
import Request from '../../Pages/Users/Request/Request';
import ViewRequest from '../../Pages/Users/Request/ViewRequest';
import VerifyRequest from '../../Pages/Users/Qc/VerifyRequest/VerifyRequest';
import SalesOrder from '../../Pages/Users/Transaction/Sale/SalesOrder';
import ManageSaleOrder from '../../Pages/Users/Transaction/Sale/ManageSaleOrder';
import Purchase from '../../Pages/Users/Transaction/Purchase/Purchase';
import Item from '../../Pages/Users/Item/Item';
import ManageItem from '../../Pages/Users/Item/ManageItem';
import Party from '../../Pages/Users/Party/Party';
import ManageParty from '../../Pages/Users/Party/ManageParty';
import OfferList from '../../Pages/Users/MaterialCoordinator/OfferList';
import ViewOfferList from '../../Pages/Users/MaterialCoordinator/ViewOfferList';
import ApprovedItemList from '../../Pages/Users/MaterialCoordinator/ApprovedItemList';
import ManageIssue from '../../Pages/Users/MaterialCoordinator/ManageIssue';
import Category from '../../Pages/Users/StoreMaster/Category/Category';
import ManageCategory from '../../Pages/Users/StoreMaster/Category/ManageCategory';
import Unit from '../../Pages/Users/StoreMaster/Unit/Unit';
import ManageUnit from '../../Pages/Users/StoreMaster/Unit/ManageUnit';
import Transport from '../../Pages/Users/StoreMaster/Transport/Transport';
import ManageTransport from '../../Pages/Users/StoreMaster/Transport/ManageTransport';
import Location from '../../Pages/Users/StoreMaster/InventoryLocation/Location';
import ManageLocation from '../../Pages/Users/StoreMaster/InventoryLocation/ManageLocation';
import PartyGroup from '../../Pages/Users/StoreMaster/PartyGroup/PartyGroup';
import ManagePartyGroup from '../../Pages/Users/StoreMaster/PartyGroup/ManagePartyGroup';
import ViewQcRequest from '../../Pages/Users/Qc/VerifyRequest/ViewQcRequest';
import ViewApprovedList from '../../Pages/Users/MaterialCoordinator/ViewApprovedList';
import Profile from '../../Pages/Users/Profile/Profile';
import IssueList from '../../Pages/Users/Transaction/Issue/IssueList';
import StockReport from '../../Pages/Users/Report/Stock/StockReport';
import AuthList from '../../Pages/Users/StoreMaster/AuthPerson/AuthList';
import ManageAuth from '../../Pages/Users/StoreMaster/AuthPerson/ManageAuth';
import IssueRequest from '../../Pages/Users/Transaction/Issue/IssueRequest';
import Fitup from '../../Pages/Users/Execution/Fitup/Fitup';
import ManageFitup from '../../Pages/Users/Execution/Fitup/ManageFitup';
import WeldVisual from '../../Pages/Users/Execution/WeldVisual/WeldVisual';
import ManageWeldVisual from '../../Pages/Users/Execution/WeldVisual/ManageWeldVisual';
import ManageWpsMaster from '../../Pages/Users/Project/WpsMaster/ManageWpsMaster';
import WpsMaster from '../../Pages/Users/Project/WpsMaster/WpsMaster';
import JointType from '../../Pages/Users/Project/JointType/JointType';
import ManageJointType from '../../Pages/Users/Project/JointType/ManageJointType';
import NdtMaster from '../../Pages/Users/Project/NdtMaster/NdtMaster';
import ManageNdt from '../../Pages/Users/Project/NdtMaster/ManageNdt';
import PaintSystem from '../../Pages/Users/Project/PaintingSystem/PaintSystem';
import ManagePaintingSystem from '../../Pages/Users/Project/PaintingSystem/ManagePaintingSystem';
import Contractor from '../../Pages/Users/Project/Contractor/Contractor';
import ManageContractor from '../../Pages/Users/Project/Contractor/ManageContractor';
import ManageWelder from '../../Pages/Users/Project/WelderMaster/ManageWelder';
import WelderMaster from '../../Pages/Users/Project/WelderMaster/WelderMaster';
import ManageProcedure from '../../Pages/Users/Project/ProcedureMaster/ManageProcedure';
import ProcedureMaster from '../../Pages/Users/Project/ProcedureMaster/ProcedureMaster';
import OfferRequest from '../../Pages/Users/Request/OfferRequest';
import QcVerify from '../../Pages/Users/Qc/VerifyRequest/QcVerify';
import Ndt from '../../Pages/Users/NDT/NDT/Ndt';
import ManageNdtMaster from '../../Pages/Users/NDT/NDT/ManageNdtMaster';
import QFitup from '../../Pages/Users/QualityClearance/QFitup/QFitup';
import QWeldVisual from '../../Pages/Users/QualityClearance/QWeldVisual/QWeldVisual';
import StockReportList from '../../Pages/Users/Stock/StockReportList';
import IssueRequestList from '../../Pages/Users/Transaction/Issue/IssueRequestList';
import ManageIssueAcc from '../../Pages/Users/Transaction/Issue/ManageIssueAcc';
import UtOffer from '../../Pages/Users/NDT/UT/UtOffer';
import ManageUtOffer from '../../Pages/Users/NDT/UT/ManageUtOffer';
import RtOffer from '../../Pages/Users/NDT/RT/RtOffer';
import ManageRtOffer from '../../Pages/Users/NDT/RT/ManageRtOffer';
import MptOffer from '../../Pages/Users/NDT/MPT/MptOffer';
import ManageMptOffer from '../../Pages/Users/NDT/MPT/ManageMptOffer';
import LptOffer from '../../Pages/Users/NDT/LPT/LptOffer';
import ManageLptOffer from '../../Pages/Users/NDT/LPT/ManageLptOffer';
import UtClearance from '../../Pages/Users/NDT/UT/UtClearance';
import GetUtClearance from '../../Pages/Users/NDT/UT/GetUtClearance';
import ManageRtClearance from '../../Pages/Users/NDT/RT/ManageRtClearance';
import RtClearance from '../../Pages/Users/NDT/RT/RtClearance';
import MptClearance from '../../Pages/Users/NDT/MPT/MptClearance';
import ManageMptClearance from '../../Pages/Users/NDT/MPT/ManageMptClearance';
import LptClearance from '../../Pages/Users/NDT/LPT/LptClearance';
import ManageLptClearance from '../../Pages/Users/NDT/LPT/ManageLptClearance';
import FinalDimension from '../../Pages/Users/Execution/FinalDimension/FinalDimension';
import ManageFinalDimension from '../../Pages/Users/Execution/FinalDimension/ManageFinalDimension';
import QFinalDimension from '../../Pages/Users/QualityClearance/FinalDimension/QFinalDimension';
import InspectionSummary from '../../Pages/Users/PaintingDispatch/InspectionSummary';
import ManageInspection from '../../Pages/Users/PaintingDispatch/ManageInspection';
import DispatchNote from '../../Pages/Users/PaintingDispatch/DispatchNote';
import ManageDispatchNote from '../../Pages/Users/PaintingDispatch/ManageDispatchNote';

import ManageSurfaceOffer from '../../Pages/Users/Paint/SurfacePrimer/ManageSurfaceOffer';
import SurfacePrimerOffer from '../../Pages/Users/Paint/SurfacePrimer/SurfacePrimerOffer';
import SurfacePrimerClearance from '../../Pages/Users/Paint/SurfacePrimer/SurfacePrimerClearance';
import MioPaint from '../../Pages/Users/Paint/Mio/MioPaint';
import ManageMioPaint from '../../Pages/Users/Paint/Mio/ManageMioPaint';
import FinalCoatPaint from '../../Pages/Users/Paint/FinalCoat/FinalCoatPaint';
import ManageFinalCoatPaint from '../../Pages/Users/Paint/FinalCoat/ManageFinalCoatPaint';
import ManageSurfaceClearance from '../../Pages/Users/Paint/SurfacePrimer/ManageSurfaceClearance';
import PaintManufacture from '../../Pages/Users/Project/PaintManufacture/PaintManufacture';
import ManagePaintManufacture from '../../Pages/Users/Project/PaintManufacture/ManagePaintManufacture';
import MioPaintClearance from '../../Pages/Users/Paint/Mio/MioPaintClearance';
import ManageMioPaintClearance from '../../Pages/Users/Paint/Mio/ManageMioPaintClearance';
import FinalCoatClearance from '../../Pages/Users/Paint/FinalCoat/FinalCoatClearance';
import ManageFinalCoatClearance from '../../Pages/Users/Paint/FinalCoat/ManageFinalCoatClearance';
import QFitUpList from '../../Pages/Users/QualityClearance/QFitup/QFitUpList';
import QWeldVisualList from '../../Pages/Users/QualityClearance/QWeldVisual/QWeldVisualList';
import ReleaseNote from '../../Pages/Users/ReleaseNote/ReleaseNote';

const UsersRoute = () => {
    return (
        <>
            <Routes>
                <Route path='/user/project-store/dashboard' element={<Dashboard />} />
                <Route path='/user/project-store/edit-profile' element={<Profile />} />

                <Route path='/user/project-store/item-management' element={<Item />} />
                <Route path='/user/project-store/manage-item' element={<ManageItem />} />

                <Route path='/user/project-store/party-management' element={<Party />} />
                <Route path='/user/project-store/manage-party' element={<ManageParty />} />

                {/* Planner */}
                <Route path='/user/project-store/drawing-management' element={<Drawing />} />
                <Route path='/user/project-store/manage-drawing' element={<ManageDrawing />} />
                <Route path='/user/project-store/view-drawing' element={<ViewDrawing />} />


                <Route path='/user/project-store/material-request-management' element={<PurchaseRequest />} />
                <Route path='/user/project-store/manage-material-request' element={<ManagePurchaseRequest />} />

                {/* Product Store */}
                <Route path='/user/project-store/purchase-order-management' element={<PurchaseOrder />} />
                <Route path='/user/project-store/manage-purchase-order' element={<ManagePurchaseOrder />} />
                <Route path='/user/project-store/purchase-management' element={<Purchase />} />

                <Route path='/user/project-store/issue-management' element={<IssueList />} />

                <Route path='/user/project-store/sales-order-management' element={<SalesOrder />} />
                <Route path='/user/project-store/manage-sales-order' element={<ManageSaleOrder />} />

                <Route path='/user/project-store/item-request-management' element={<Request />} />
                <Route path='/user/project-store/view-item-request' element={<ViewRequest />} />

                <Route path='/user/project-store/item-records-management' element={<AdjustmentTable />} />
                <Route path='/user/project-store/stock-management' element={<Stock />} />

                {/* Material Cordinator */}
                <Route path='/user/project-store/offer-item-management' element={<OfferList />} />
                <Route path='/user/project-store/view-offered-item' element={<ViewOfferList />} />
                <Route path='/user/project-store/approved-item-management' element={<ApprovedItemList />} />
                <Route path='/user/project-store/view-approved-item' element={<ViewApprovedList />} />
                <Route path='/user/project-store/item-issue-management' element={<ManageIssue />} />

                <Route path='/user/project-store/manage-offer-request' element={<OfferRequest />} />

                {/* Masters */}
                <Route path='/user/project-store/category-management' element={<Category />} />
                <Route path='/user/project-store/manage-category' element={<ManageCategory />} />

                <Route path='/user/project-store/unit-management' element={<Unit />} />
                <Route path='/user/project-store/manage-unit' element={<ManageUnit />} />

                <Route path='/user/project-store/auth-person-management' element={<AuthList />} />
                <Route path='/user/project-store/manage-auth-person' element={<ManageAuth />} />

                <Route path='/user/project-store/transport-management' element={<Transport />} />
                <Route path='/user/project-store/manage-transport' element={<ManageTransport />} />

                <Route path='/user/project-store/inventory-location-management' element={<Location />} />
                <Route path='/user/project-store/manage-inventory-location' element={<ManageLocation />} />

                <Route path='/user/project-store/party-group-management' element={<PartyGroup />} />
                <Route path='/user/project-store/manage-party-group' element={<ManagePartyGroup />} />

                {/* QC */}
                <Route path='/user/project-store/verify-request-management' element={<VerifyRequest />} />
                <Route path='/user/project-store/view-qc-request' element={<ViewQcRequest />} />
                <Route path='/user/project-store/manage-verify-request' element={<QcVerify />} />

                <Route path='/user/project-store/issue-request-management' element={<IssueRequestList />} />
                <Route path='/user/project-store/manage-issue-request' element={<IssueRequest />} />
                {/* <Route path='/user/project-store/manage-issue-acceptance' element={<CreateIssue />} /> */}
                <Route path='/user/project-store/manage-issue-acceptance' element={<ManageIssueAcc />} />

                {/* Execution  */}
                <Route path='/user/project-store/fitup-management' element={<Fitup />} />
                <Route path='/user/project-store/manage-fitup' element={<ManageFitup />} />

                <Route path='/user/project-store/weld-visual-management' element={<WeldVisual />} />
                <Route path='/user/project-store/manage-weld-visual' element={<ManageWeldVisual />} />

                {/* Project Masters */}
                <Route path='/user/project-store/manage-wps-master' element={<ManageWpsMaster />} />
                <Route path='/user/project-store/wps-master-management' element={<WpsMaster />} />

                <Route path='/user/project-store/joint-type-management' element={<JointType />} />
                <Route path='/user/project-store/manage-joint-type' element={<ManageJointType />} />

                <Route path='/user/project-store/ndt-master-management' element={<NdtMaster />} />
                <Route path='/user/project-store/manage-ndt-master' element={<ManageNdt />} />

                <Route path='/user/project-store/painting-system-management' element={<PaintSystem />} />
                <Route path='/user/project-store/manage-painting-system' element={<ManagePaintingSystem />} />

                <Route path='/user/project-store/contractor-master-management' element={<Contractor />} />
                <Route path='/user/project-store/manage-contractor-master' element={<ManageContractor />} />

                <Route path='/user/project-store/welder-management' element={<WelderMaster />} />
                <Route path='/user/project-store/manage-welder' element={<ManageWelder />} />

                <Route path='/user/project-store/procedure-master-management' element={<ProcedureMaster />} />
                <Route path='/user/project-store/manage-procedure-master' element={<ManageProcedure />} />

                {/* Quality Clearance */}
                <Route path='/user/project-store/fitup-clearance-management' element={<QFitUpList />} />
                <Route path='/user/project-store/quality-clearance-fitup-management' element={<QFitup />} />

                <Route path='/user/project-store/weld-visual-clearance-management' element={<QWeldVisualList />} />
                <Route path='/user/project-store/quality-clearance-weld-visual-management' element={<QWeldVisual />} />

                {/* NDT */}
                <Route path='/user/project-store/ndt-management' element={<Ndt />} />
                <Route path='/user/project-store/manage-ndt' element={<ManageNdtMaster />} />

                <Route path='/user/project-store/ut-offer-management' element={<UtOffer />} />
                <Route path='/user/project-store/manage-ut-offer' element={<ManageUtOffer />} />
                <Route path='/user/project-store/ut-clearance-management' element={<GetUtClearance />} />
                <Route path='/user/project-store/manage-ut-clearance' element={<UtClearance />} />

                <Route path='/user/project-store/rt-offer-management' element={<RtOffer />} />
                <Route path='/user/project-store/manage-rt-offer' element={<ManageRtOffer />} />
                <Route path='/user/project-store/rt-clearance-management' element={<RtClearance />} />
                <Route path='/user/project-store/manage-rt-clearance' element={<ManageRtClearance />} />

                <Route path='/user/project-store/mpt-offer-management' element={<MptOffer />} />
                <Route path='/user/project-store/manage-mpt-offer' element={<ManageMptOffer />} />
                <Route path='/user/project-store/mpt-clearance-management' element={<MptClearance />} />
                <Route path='/user/project-store/manage-mpt-clearance' element={<ManageMptClearance />} />

                <Route path='/user/project-store/lpt-offer-management' element={<LptOffer />} />
                <Route path='/user/project-store/manage-lpt-offer' element={<ManageLptOffer />} />
                <Route path='/user/project-store/lpt-clearance-management' element={<LptClearance />} />
                <Route path='/user/project-store/manage-lpt-clearance' element={<ManageLptClearance />} />

                <Route path='/user/project-store/final-dimension-offer-management' element={<FinalDimension />} />
                <Route path='/user/project-store/manage-final-dimension-offer' element={<ManageFinalDimension />} />
                <Route path='/user/projct-store/quality-clearance-final-dimension-management' element={<QFinalDimension />} />

                <Route path='/user/project-store/inspection-summary-management' element={<InspectionSummary />} />
                <Route path='/user/project-store/manage-inspection-summary' element={<ManageInspection />} />

                <Route path='/user/project-store/dispatch-note-management' element={<DispatchNote />} />
                <Route path='/user/project-store/manage-dispatch-note' element={<ManageDispatchNote />} />

                {/* Painting System */}
                <Route path='/user/project-store/paint-manufacture-management' element={<PaintManufacture />} />
                <Route path='/user/project-store/manage-paint-manufacture' element={<ManagePaintManufacture />} />

                <Route path='/user/project-store/surface-primer-management' element={<SurfacePrimerOffer />} />
                <Route path='/user/project-store/manage-surface-primer' element={<ManageSurfaceOffer />} />
                <Route path='/user/project-store/surface-clearance-management' element={<SurfacePrimerClearance />} />
                <Route path='/user/project-store/manage-surface-clearance' element={<ManageSurfaceClearance />} />

                <Route path='/user/project-store/mio-offer-management' element={<MioPaint />} />
                <Route path='/user/project-store/manage-mio-offer' element={<ManageMioPaint />} />
                <Route path='/user/project-store/mio-clearance-management' element={<MioPaintClearance />} />
                <Route path='/user/project-store/manage-mio-clearance' element={<ManageMioPaintClearance />} />

                <Route path='/user/project-store/final-coat-management' element={<FinalCoatPaint />} />
                <Route path='/user/project-store/manage-final-coat' element={<ManageFinalCoatPaint />} />
                <Route path='/user/project-store/final-coat-clearance-management' element={<FinalCoatClearance />} />
                <Route path='/user/project-store/manage-final-coat-clearance' element={<ManageFinalCoatClearance />} />

                <Route path='/user/project-store/release-note-management' element={<ReleaseNote />} />


                <Route path='/user/project-store/stock-report' element={<StockReport />} />
                <Route path='/user/project-store/stock-report-management' element={<StockReportList />} />

                <Route path='/user/project-store/*' element={<Navigate to='/user/project-store/dashboard' />} />
            </Routes>
        </>
    )
}

export default UsersRoute