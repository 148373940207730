
// export const V_URL = "http://localhost:5000/api";

// export const V_URL = "https://api.addonwebtech.com/api";
export const V_URL = "https://api.vrishal.in/api";

export const PAY = "Payroll System";
export const M_STORE = "Main Store";
export const P_STORE = "Project Store";
export const ERP = "Project Fabrication";

export const PLAN = 'Planning Engineer';
export const QC = 'QC Engineer';
export const M_CON = 'Material Controller';

export const PMS = 'Project Fabrication';
